<template>
  <div v-editable="editing && blok" class="footer-section">
    <span v-if="blok.headline != ''">{{blok.headline}}</span>
    <StoryblokComponent v-for="blok in blok.content" :key="blok._uid" :blok="blok" class="ignore-richtext" />
   
  </div>
</template>

<script setup>
defineProps({ blok: Object })
  const { editing } = useSettings();
</script>
<style lang="scss">
  .footer-section{
    h3{
      font-weight: bold;
      font-style: italic;
      // margin-bottom: 1rem;
    }
    > *{
      &:not(:last-child){
        margin-bottom:.75rem;
      }
    }
    .richtext:not(.ignore-richtext){
      // a[href]{
      //   color:currentColor ;
      // }
    }
  }
</style>
